import { createSelector } from 'reselect';
import { RootState } from 'src/state/reducer';

const requestLoadingStateSelector = (state: RootState) => state.requestLoading;

export const createRequestLoadingSelector = () =>
  createSelector(
    [
      requestLoadingStateSelector,
      (_: RootState, actionName: string) => actionName,
    ],
    (state, actionName) => state[actionName]
  );
