import { createSelector } from 'reselect';
import { RootState } from 'src/state/reducer';

const requestTimestampStateSelector = (state: RootState) =>
  state.requestTimestamp;

export const createRequestTimestampSelector = () =>
  createSelector(
    [
      requestTimestampStateSelector,
      (_: RootState, actionName: string) => actionName,
    ],
    (state, actionName) => state[actionName]
  );
