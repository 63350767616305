import { createSelector } from 'reselect';
import { RootState } from 'src/state/reducer';

const requestStatusStateSelector = (state: RootState) => state.requestStatus;

export const createRequestStatusSelector = () =>
  createSelector(
    [
      requestStatusStateSelector,
      (_: RootState, actionName: string) => actionName,
    ],
    (state, actionName) => state[actionName]
  );
